import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
// import ButtonCallMeBackInModal from '../../Components/Shared/ButtonCallMeBackInModal';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: 'white',
  },
  layout: {
    ...LayoutStyles.centered800Layout,
    margin: '4rem 2rem 4rem',
  },
  title: {
    padding: theme.spacing(8, 0),
  },
  stepper: {
    '& .MuiStepIcon-active': {
      color: theme.current.primary.main,
    },
    '& .MuiStepIcon-completed': {
      color: theme.current.primary.main,
    },
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

const Methode = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = [
    {
      label: 'Etape 1 : Identification d’une personne',
      description:
        '<p>Cette première étape est relative à l’identification de la personne qui peut être bénéficiaire de la méthode. Concrètement, cette identification a été portée les professionnels co- chercheurs engagés dans le cadre de la recherche-action collaborative et qui, au fur et à mesure de la démarche, ont pu échanger avec leurs collègues de situations que connaissent des personnes âgées de plus de 65 ans en perte d’autonomie et pour qui l’isolement social ou le sentiment de solitude pouvait amener l’intervenant à pouvoir proposer la méthode REPRISE.</p>',
    },
    {
      label: 'Etape 2 : Présentation de la démarche et recueil de l’adhésion',
      description:
        '<p>D’autant plus s’agissant d’une recherche-action, il y a lieu d’informer la personne bénéficiaire de la nature du projet et de ce qui est attendu d’elle.</p><p>Une fois la démarche de recherche validée, cette étape reste importante dans le déroulé du plan d’action, afin de garantir l’engagement de la personne en fonction d’un avis éclairé. Pour ce faire, un discours a été produit permettant d’énoncer clairement ce qu’est la méthode REPRISE.</p>',
    },
    {
      label: 'Etape 3 : Recueil d’information et aide à l’exploration et l’expression des envies',
      description:
        '<p>Cette étape s’inscrit déjà dans une interconnaissance entre la personne bénéficiaire et le ou les repriseurs. Ici on peut s’attendre à ce qu’au moins une première rencontre ait permis en amont un premier échange, la présentation de la méthode et le recueil du consentement. Il s’agit alors d’en savoir un peu plus sur l’état de la personne, en discutant autour de : <ul><li>Son autoportrait</li><li>Son rapport au quotidien</li><li>Son rapport à la famille</li><li>Son rapport à la vie sociale</li><li>Son rapport à la santé</li></ul></p><p>C’est ici également que la personne est amenée à s’exprimer sur sa motivation et sur l’envie qu’elle souhaiterait pouvoir réaliser. On verra dans la suite de ce rapport de recherche que l’expression de l’envie peut faire l’objet parfois de réaménagement, au cours du déroulement du plan d’action, au profit le plus souvent d’envies plus réalistes et réalisables.</p><p>Il s’agit ici d’être particulièrement vigilant à ce que les envies exprimées soient bien celles de la personne bénéficiaire, et non celle de son entourage (même bienveillant), ni des projections de l’entourage sur le bénéficiaire.</p><p>Ces trois premières étapes trouvent leur support dans la Grille de recueil de données dans le cadre de la mobilisation REPRISE, grille qui sera exposée dans les pages suivantes.</p>',
    },
    {
      label: 'Etape 4 : Repérage et mobilisation du réseau',
      description:
        '<p>Une fois l’envie exprimée et discernée, la méthode REPRISE est basée sur l’identification des ressources autour de la personne bénéficiaire, ressources qui composent déjà ou peuvent dorénavant composer son réseau social.</p><p>Ici, il nous fallait un support dédié à cet objectif et c’est le sociogramme qui peut servir ce but. Nous présenterons dans les pages suivantes ce qu’est le sociogramme.</p>',
    },
    {
      label:
        'Etape 5 : Explicitation et explication du plan d’action à la personne et à son réseau',
      description:
        '<p>L’étape suivante consiste à pouvoir repérer et identifier finement ce qui pourrait être activé en termes de réseau, autour de la personne bénéficiaire. Celle-ci doit être particulièrement sollicitée dans cette étape car on ne crée le lien qu’avec le réseau que la personne elle-même souhaite faire intervenir ; c’et son réseau.</p><p>Pour que cette mise en lien puisse se faire, encore faut-il pouvoir communiquer auprès de la personne bénéficiaire ce que propose le réseau.</p><p>C’est donc dans cette étape que le REPRISEUR démarche auprès de bénévoles, services ou autres en fonction de et à propos de l’envie à réaliser.</p><p>Le bénéficiaire peut être actif dans cette étape, comme nous le montrerons les résultats de cette recherche, et partir lui aussi en quête de pourvoyeurs de soutien à la réalisation de l’envie.</p>',
    },
    {
      label: 'Etape 6 : Réalisation de l’envie par la personne et son réseau',
      description:
        '<p>Il s’agit dans cette étape de laisser réaliser l’envie par la personne bénéficiaire avec les membres du réseau qui auront été sollicités pour le faire. Le rôle du Repriseur n’est pas de réaliser lui-même l’envie (ce qui peut susciter parfois quelque frustration, comme nous le verrons dans la partie Résultat de la recherche), mais bien de déclencher l’activation d’un réseau qui pourra d’ailleurs persister au-delà de l’action REPRISE. Le repriseur ne fait ici qu’acter de la réalisation ou de la non-réalisation, de l’opportunité de cette mise en lien et aussi de l’adaptation qui a éventuellement été nécessaire dans cette réalisation.</p>',
    },
    {
      label: 'Etape 7 : Constater la réalisation et mesurer les effets (modifié) ',
      description:
        '<p>Le Repriseur est engagé dans une relation au titre de laquelle il veille à la satisfaction du bénéficiaire dans ce protocole d’action. Il a donc pour rôle de s’assurer de la réalisation de l’envie et d’en mesurer les effets avec la personne bénéficiaire, voire avec son entourage proche.</p><p>Dans cette étape, il peut aussi prendre en compte les effets de cette intervention sur l’aidant.</p>',
    },
  ];

  return (
    <Grid className={classes.mainLayout}>
      <Grid className={classes.layout}>
        <Typography variant="h3" component="h2" align="center" className={classes.title}>
          La méthode REPRISE en 7 étapes
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: t(step.description),
                  }}
                />
                <div>
                  {
                    index !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        onClick={() => setActiveStep(activeStep + 1)}
                        className={classes.btnColor}
                      >
                        {index === steps.length - 1 ? 'Participer' : t('NEXT')}
                      </Button>
                    )
                    // <ButtonCallMeBackInModal
                    //   withContainedButton
                    //   withPhone
                    //   withEmail
                    //   title="Participez à la méthode Reprise"
                    //   description="Vous souhaitez que l'on vous aide à réaliser vos envies, alors envoyez-nous une demande !"
                    //   buttonLabel="Participer"
                    //   descriptionLabel="Votre message"
                    //   descriptionPlaceHolder="Je souhaite participer à la méthode REPRISE"
                    //   gtmOrigin="landing"
                    // />
                  }
                  {index !== 0 && (
                    <Button onClick={() => setActiveStep(activeStep - 1)}>{t('BACK')}</Button>
                  )}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
};

export default Methode;
