import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import apisauce from 'apisauce';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import { isAttributeDefined } from '../../Services/FormHelpers';
import { checkEmailValidity } from '../../Services/DataHelper';
import Routes from '../../Routing/Routes';

type Props = {
  classes: Object,
};

const styles = theme => ({
  footer: {
    backgroundColor: '#0f2135',
    padding: theme.spacing(4),
  },
  link: {
    textDecorationLine: 'none',
    textTransform: 'none',
    color: 'white',
    padding: 0,
    fontSize: 'inherit',
  },
  footerText: {
    color: 'white',
  },
});

function Footer({ classes }: Props) {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);
  const [openDialog, setOpenDialog] = React.useState(null);
  const [formData, setFormData] = React.useState({
    firstName: currentUser?.first_name || '',
    lastName: currentUser?.last_name || '',
    email: currentUser?.email || '',
    description: '',
  });
  const [emailError, setEmailError] = React.useState(false);

  const onSubmit = () => {
    if (!emailError) {
      apiFeedbackIntegromat.post('', {
        feedback: formData,
        league_slug: Routes.currentLeague().slug,
        env: process.env.RAZZLE_ENVIRONMENT,
        route_path: history.location?.pathname,
        type: openDialog,
      });
      closeDialog();
    }
  };

  const apiFeedbackIntegromat = apisauce.create({
    baseURL: 'https://hook.integromat.com/mrjry3fyttlvyosvyi4r2kh7y7z8qaqd',
  });

  const closeDialog = () => {
    setOpenDialog(null);
    setFormData({
      firstName: currentUser?.first_name || '',
      lastName: currentUser?.last_name || '',
      email: currentUser?.email || '',
      description: '',
    });
  };

  return (
    <>
      <footer className={classes.footer}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            {!['SLA', 'CHB'].includes(Routes.currentNamespace()) && (
              <>
                <Button
                  href="https://www.linkedin.com/company/kiosc"
                  target="_blank"
                  className={classes.link}
                  style={{ display: 'block' }}
                >
                  <Typography variant="caption" className={classes.footerText}>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t('WELLO.COPYRIGHT'),
                      }}
                    />
                  </Typography>
                </Button>
                <Typography variant="caption" className={classes.footerText}>
                  {t('WELLO.ADDRESS')}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {Routes.currentNamespace() === 'CHB' && (
                <Button
                  href="https://www.centre-helene-borel.com/"
                  target="_blank"
                  className={classes.link}
                >
                  {t('Centre Hélène Borel')}
                </Button>
              )}
              {t('SPACE')}
              <Button href="/conditions-generales-d-utilisation" className={classes.link}>
                {t('WELLO.CGU.TITLE')}
              </Button>
              {t('SPACE')}
              <Button href="/politique-de-confidentialite" className={classes.link}>
                {t('WELLO.PRIVACY.TITLE')}
              </Button>
              {t('SPACE')}
              <Button href="/mentions-legales" className={classes.link}>
                {t('WELLO.MENTION.TITLE')}
              </Button>
              {t('SPACE')}
              <Button
                href={
                  Routes.currentNamespace() === 'CHB'
                    ? 'mailto:pfs@centre-helene-borel.com'
                    : 'mailto:hello@wello.fr'
                }
                className={classes.link}
              >
                {t('WELLO.CONTACT.TITLE')}
              </Button>
              {t('SPACE')}
              <Button onClick={() => setOpenDialog('error')} className={classes.link}>
                {t('WELLO.FEEDBACK.ERROR.TITLE')}
              </Button>
              {t('SPACE')}
              {Routes.currentNamespace() !== 'CHB' && (
                <Button onClick={() => setOpenDialog('improvement')} className={classes.link}>
                  {t('WELLO.FEEDBACK.IMPROVEMENT.TITLE')}
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>
      </footer>
      <Dialog open={Boolean(openDialog)} onClose={() => closeDialog()}>
        <form onSubmit={onSubmit}>
          <DialogTitle>
            <Typography variant="h5" component="div" align="center">
              {t(
                openDialog === 'error'
                  ? 'WELLO.FEEDBACK.ERROR.TITLE'
                  : 'WELLO.FEEDBACK.IMPROVEMENT.TITLE',
              )}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="firstName"
                  value={formData.firstName}
                  type="text"
                  label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.FIRST_NAME')}
                  onChange={event => setFormData({ ...formData, firstName: event.target.value })}
                  disabled={isAttributeDefined('first_name')(currentUser)}
                  fullWidth
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="lastName"
                  value={formData.lastName}
                  type="text"
                  label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.LAST_NAME')}
                  onChange={event => setFormData({ ...formData, lastName: event.target.value })}
                  disabled={isAttributeDefined('last_name')(currentUser)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="email"
                  value={formData.email}
                  type="text"
                  label={t('APP.ASK_CONTACT.TEXT_FIELD.LABEL.EMAIL.SUFFIX')}
                  onChange={event =>
                    setFormData({ ...formData, email: event.target.value?.trim() })
                  }
                  onBlur={() => setEmailError(!checkEmailValidity(formData.email))}
                  helperText={emailError}
                  error={formData.email && !checkEmailValidity(formData.email)}
                  disabled={isAttributeDefined('email')(currentUser)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  value={formData.description}
                  type="text"
                  placeholder={t(
                    openDialog === 'error'
                      ? 'WELLO.FEEDBACK.ERROR.DESCRIPTION_PLACEHOLDER'
                      : 'WELLO.FEEDBACK.IMPROVEMENT.DESCRIPTION_PLACEHOLDER',
                  )}
                  label={t(
                    openDialog === 'error'
                      ? 'WELLO.FEEDBACK.ERROR.DESCRIPTION_LABEL'
                      : 'WELLO.FEEDBACK.IMPROVEMENT.DESCRIPTION_LABEL',
                  )}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  onChange={event => setFormData({ ...formData, description: event.target.value })}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={!checkEmailValidity(formData.email)}
            >
              {t('APP.ASK_CALL.ACTION.SEND')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(Footer);
